export default {
  install(Vue) {
    Vue.prototype.$urlHelper = {
      fixDiacritice(source) {
        return source
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9-_]/g, "_")
          .replace(/-/g, "")
          .replace(/__+/g, "_");
      },
    };
  },
};
