<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac text-break">The Most Unusual Website on the Internet</h1>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex justify-center text-center text-break">
          If you found us “by accident,” Welcome.<br />
          (Please read all about us and see why we are so different)<br /><br />
          If you have been referred by a client, a friend, or an art broker;
          again, Welcome!<br />
          (Please read our story, even though you may have already been told a
          great deal about us)
        </div>
      </v-card-text>
    </v-banner>
    <router-link to="/home">
      <v-banner>
        <v-img id="hero" dark :src="imgLink.background" width="100%"> </v-img>
      </v-banner>
    </router-link>
    <div class="mt-8">
      <router-link class="simpleText" to="/home">
        <v-col class="text-center" cols="12">
          <v-img :src="imgLink.logo" max-height="120px" contain> </v-img>
          <h2 class="ac subheading mb-14 text-break">Swords of Art & Elegance</h2>
          <h4 class="subheading text-break">By Referral Only</h4>
        </v-col>
      </router-link>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "CarmelSamuraiCompany",
  metaInfo() {
    return {
      title: "Carmel Samurai Company",
      meta: [
        { name: "description", content: "Swords of Art & Elegance" },
        { property: "og:title", content: "Carmel Samurai Company" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      imgLink: {
        background: require("@/assets/coverPage.png"),
        logo: require("@/assets/logo-light.png"),
      },
    };
  },
};
</script>
