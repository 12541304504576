<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">The Pleiades / 7 Sisters Project</h1>
      </v-card-title>
    </v-banner>
    <v-banner>
      <v-card-subtitle>
        <h2 class="text-center ac">
          The First Limited Edition Wakizashi Set in Samurai History
        </h2>
      </v-card-subtitle>
      <v-img id="heroSword" height="280" contain dark :src="imgLink.sword">
      </v-img>
    </v-banner>
    <v-row class="my-2">
      <v-col cols="12">
        <h2 class="text-center ac">
          The Pleiades is a star constellation that has been honored in all
          cultures.
        </h2>
      </v-col>
      <v-col cols="12">
        <h3 class="text-center mx-auto ac" style="max-width: 650px">
          Also called the “The 7 Sisters” for the seven major, brightest,
          blue-white stars of the cluster, they hold a special place in Japanese
          mythology and legend.
        </h3>
      </v-col>
    </v-row>
    <v-banner>
      <v-parallax id="hero" height="250" dark :src="imgLink.background">
      </v-parallax>
    </v-banner>
    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="6">
        <p class="mx-4">
          Renowned Japanese Master Swordsmith, Matsuba Kunimasa has been chosen
          to be the creator of a unique set of samurai wakizashi.<br />
          A series of 7 – all with different themes and all mounted with genuine
          samurai antique fittings.<br />
          Each sword is named, numbered, and registered.<br />

          Each is accompanied by a custom book explaining the Series, name of
          the original owner, and providing a provenance of the fittings.<br />
          Each book presents photos of the wakizashi and the proud owner.<br />
          Upon completion of the series there will be one compilation book that
          features all information of each sword in the series.<br />
        </p>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        Copies of that book will be sent to every major museum in the world that
        carries a Samurai Collection.<br />
        Think of what that means – your name forever associated with that
        sword.<br />
        Immortality for all the original owners of this unique series.<br />
        This first-of-its-kind Wakizashi series advances the concept of The
        Samurai Sword as Fine Art.<br />
        This has been the stated objective of the Carmel Samurai Company since
        its inception.<br />
      </v-col>
    </v-row>
    <v-row
      ><v-col cols="12"><v-divider></v-divider></v-col
    ></v-row>
    <v-row>
      <v-col cols="12" class="mx-4">
        <h3>The Sisters</h3>
      </v-col>
      <v-col
        v-for="sword in swords"
        :key="sword.id"
        cols="12"
        md="3"
        lg="3"
        sm="12"
      >
        <v-card class="mx-auto" :to="sword.url">
          <v-img :src="sword.cover" height="130" contain>
            <div class="sold" v-if="sword.isSold">Sold</div>
          </v-img>
          <v-card-title> #{{ sword.number }} {{ sword.name }} </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col>
        <h3 class="ac grey--text">
          The Pleiades / 7 Sisters, Limited Wakizashi Series is the creative
          idea of A. Taum Dell’Armo, Founder/Director, Carmel Samurai Company,
          Carmel, CA, USA and the master craftsmanship of the world-renowned
          master swordsmith Ichiro Matsuba Kunimasa.
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12">
        <v-card dark tile outlined>
          <v-col cols="12" class="mx-4">
            <p class="mt-2">
              Each owner of a Wakizashi in the Pleiades/7 Sisters Series gets a
              pure silver coin specially minted by the Royal Australian Mint in
              2021, as a limited series to honor the constellation.
            </p>
          </v-col>
          <v-col cols="12">
            <Gallery :height="200" :images="caImages" :contain="true"></Gallery>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Gallery from "@/components/Gallery";
export default {
  name: "HomePage",
  components: {
    Gallery,
  },
  metaInfo() {
    return {
      title: "The Pleiades – The 7 Sisters Star Constellation",
      meta: [
        {
          name: "description",
          content: "The First Limited Edition Wakizashi Set in Samurai History",
        },
        {
          property: "og:title",
          content: "The First Limited Edition Wakizashi Set in Samurai History",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      imgLink: {
        background: require("@/assets/7sistersCover.jpeg"),
        sword: require("@/assets/7sisters/sister-cover.png"),
      },
      samuraiImages: [
        {
          id: 1,
          imgSrc: require("@/assets/company/samuraiTaum.jpeg"),
          ratio: "1.7",
        },
      ],
      caImages: [
        {
          id: 1,
          imgSrc: require("@/assets/7sisters/ca-1.jpeg"),
          ratio: "1",
          sold:true
        },
        {
          id: 2,
          imgSrc: require("@/assets/7sisters/ca-2.jpeg"),
          ratio: "1.7",
          sold: true
        },
        {
          id: 3,
          imgSrc: require("@/assets/7sisters/ca-3.jpeg"),
          ratio: "1.7",
          sold: false
        },
      ],
      swords: [
        {
          number: 1,
          name: "Kaida/Little Dragon",
          isSold: true,
          cover: require("@/assets/7sisters/1-cover.jpeg"),
          url:
            "/the-pleiades/" +
            this.$urlHelper.fixDiacritice("Kaida/Little Dragon ") +
            "-1",
        },
        {
          number: 2,
          name: "Dream Catcher",
          isSold: true,
          cover: require("@/assets/7sisters/2-cover.jpeg"),
          url:
            "/the-pleiades/" +
            this.$urlHelper.fixDiacritice("Dream Catcher") +
            "-2",
        },
        {
          number: 3,
          name: "Carp/Dragon’s Gate",
          isSold: true,
          cover: require("@/assets/7sisters/carp/B.jpeg"),
          url:
            "/the-pleiades/" +
            this.$urlHelper.fixDiacritice("Carp/Dragon’s Gate") +
            "-3",
        },
      ],
    };
  },
};
</script>
<style>
#hero .v-parallax__content {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.45) 25%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.45) 75%,
    rgba(0, 0, 0, 1) 100%
  );
}
#heroSword .v-parallax__content {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.45) 25%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.45) 75%,
    rgba(0, 0, 0, 1) 100%
  );
}
h3.ac {
  font-size: 22px;
}
h3 {
  font-size: 18px;
  line-height: 1.2;
}
div.sold {
  height: 25px;
  width: 180px;
  background-color: #9C1B21;
  position: absolute;
  top: 40px;
  right: -10px;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  font-size: 24px;
  white-space: nowrap;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  font-family: "Apple Chancery", "Helvetica Neue", Helvetica;
}

.sold:before {
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-width: 37px;
  border-left-width: 53px;
  border-right-width: 26px;
  content: "";
  display: block;
  left: -49px;
  position: absolute;
  width: 141%;
  border-top-width: 0px;
  border-bottom-color: #9c1b21;
  top: -2px;
  z-index: -1;
}
</style>
