import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import logger from "./plugins/logger";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import "vuetify/dist/vuetify.min.css";
import "vue-toastification/dist/index.css";
import urlHelper from "@/plugins/urlHelper";
import titleMixin from "./plugins/titleMixin";
import store from "./store";
import VuePictureSwipe from 'vue-picture-swipe';

Vue.config.productionTip = false;
Vue.mixin(titleMixin); // auto set title
Vue.use(urlHelper);
Vue.use(
  VueGtag,
  {
    config: { id: "G-F669HGQ975" },
    appName: "CarmelSamuraiCompany",
    pageTrackerScreenviewEnabled: true,
  },
  router
);
Vue.component('vue-picture-swipe', VuePictureSwipe);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 3,
  newestOnTop: true,
  position: "top-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.7,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
});
Vue.use(VueMeta);
new Vue({
  router,
  logger,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
