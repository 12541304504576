<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">Links</h1>
      </v-card-title>
    </v-banner>
    <v-banner>
      <v-img id="hero" dark :src="imgLink.background"> </v-img>
    </v-banner>
    <v-row class="mt-8">
      <v-col cols="12">
        <v-list>
          <template v-for="(item, index) in items">
            <v-list-item v-if="item.action" :key="item.title">
              <v-list-item-action>
                <v-icon>{{ item.action }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-btn target="_blank" :href="item.url" plain text>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-else-if="item.divider" :key="index"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "HomePage",

  data() {
    return {
      imgLink: {
        background: require("@/assets/homePage.jpeg"),
      },
      items: [
        {
          action: "mdi-label",
          title: "Exceptional Swords and fittings",
          url: "https://www.nihonto.com",
        },
        {
          divider: true,
        },
        {
          action: "mdi-label",
          title: "Japan Sword Art",
          url: "https://www.japanswordart.com/",
        },
        {
          divider: true,
        },
        {
          action: "mdi-label",
          title: "Shibui Swords & Tsuba",
          url: "https://shibuiswords.com/",
        },
      ],
    };
  },
};
</script>
