<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">
          #{{ activeSword.number }} {{ activeSword.name }}
        </h1>
        <h2 class="pl-4" style="font-family: 'Helvetica Neue', Helvetica">
          <i v-if="activeSword.subTitle.length > 0">
            - {{ activeSword.subTitle }}</i
          >
          <i v-if="activeSword.isSold"> - SOLD</i>
        </h2>
      </v-card-title>

      <v-card-subtitle> </v-card-subtitle>
    </v-banner>
    <v-banner>
      <v-img max-height="300" dark :src="activeSword.cover"></v-img>
    </v-banner>
    <v-row class="mt-2">
      <v-col cols="12" md="6">
        <p
          v-for="text in activeSword.text"
          :key="text.id"
          class="mb-0 pb-0 mx-4"
          v-html="text.content"
        ></p>
      </v-col>
      <v-col v-if="activeSword.extraContent.length > 0" cols="12" md="6">
        <v-card
          class="mx-4"
          elevation="5"
          outlined
          tile
          if="activeSword.number === 1"
        >
          <v-card-text v-html="activeSword.extraContent"> </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!activeSword.hasGallery">
      <v-col v-if="activeSword.gallery.length > 0" cols="12">
        <v-card-title class="text-break">Click on each image to enlarge and use the arrow keys to navigate.</v-card-title>
        <vue-picture-swipe :items="activeSword.gallery"></vue-picture-swipe>
      </v-col>
      <v-col><v-divider></v-divider></v-col>
    </v-row>

    <v-row v-else>
      <v-col v-if="activeSword.gallery.length > 0" cols="12">
        <v-card-title class="text-break">Click on each image to enlarge and use the arrow keys to navigate.</v-card-title>
      </v-col>
      <mini-gal :items="activeSword.gallery"></mini-gal>
    </v-row>

    <v-row>
      <v-col cols="12"><h2 class="mb-2">The Other Samurai</h2></v-col>
      <v-col
        v-for="(sword,n) in related"
        :key="n"
        style="min-width: 380px"
        cols="12"
        md="3"
        lg="3"
        sm="12"
      >
        <v-card :to="sword.url">
          <v-img :src="sword.cover" height="200" contain></v-img>
          <span class="mx-2" style="font-size: 16px; font-weight: bold">
            #{{ sword.number }} {{ sword.name }}
          </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-10"></v-row>
  </v-container>
</template>
<script>
import MiniGal from "@/components/minigal.vue";

export default {
  name: "SamuraiPage",
  components: {
    MiniGal
  },
  metaInfo() {
    return {
      title: this.activeSword.name,
      meta: [
        {
          name: "description",
          content: this.activeSword.name + " - " + this.activeSword.text[0],
        },
        {
          property: "og:title",
          content:
            "8 Master swordsmiths forge 8 blades for 8 select clients. - " +
            this.activeSword.name,
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      swords: [
        {
          number: 0,
          name: "Placeholder",
          isSold: true,
          text: "Placeholder",
          gallery: [],
          extraContent: "",
        },
        {
          number: 1,
          title: "Samurai Spring",
          name: "Samurai Spring, Sword of Hope",
          subTitle: "",
          hasGallery:false,
          isSold: true,
          cover: require("@/assets/8samurai/1-cover.jpeg"),
          text: [
            { id: 1, content: "Private collection,USA" },
            { id: 2, content: "Custom forged by Kunimasa Matsuba" },
            {
              id: 3,
              content:
                "NBTHK Antique fittings/Birds, Cherry Blossoms, and Peony",
            },
            { id: 4, content: "Part of the 8 Samurai Collector Series" },
            {
              id: 5,
              content:
                "The symbols of nature represent power, order, beauty, and spiritual meaning<br>Spring is the season of Hope, change, renewal.<br>It is a theme the owner of this sword takes as a personal message and belief.<br>The cherry blossom, especially, was sacred to the Samurai. It reminded him that Life was both beautiful and fleeting; quick to bloom, quick to die.<br>Death is not to be feared as Life is to be fully embraced.",
            },
          ],
          gallery: [
            {
              id: 1,
              src: require("@/assets/8samurai/1-2.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/1-2_thumbnail.jpeg"),
              h:915,
              w:859,

            },
            {
              id: 2,
              src: require("@/assets/8samurai/1-3.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/1-3_thumbnail.jpeg"),
              w:1505,
              h:859,
            },
            {
              id: 3,
              src: require("@/assets/8samurai/1-4.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/1-4_thumbnail.jpeg"),
              w:1256,
              h:859,
            },
            {
              id: 4,
              src: require("@/assets/8samurai/1-5.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/1-5_thumbnail.jpeg"),
              w:1349,
              h:859,
              ratio: "1.7",
            },
            {
              id: 5,
              src: require("@/assets/8samurai/1-6.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/1-6_thumbnail.jpeg"),
              w:1289,
              h:859,
              ratio: "1.7",
            },
            {
              id: 6,
              src: require("@/assets/8samurai/1-cover.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/1-cover_thumbnail.jpeg"),
              w:1719,
              h:859,
              ratio: "1.7",
            },
          ],
          extraContent:
            "<span><i>“The selection of Matsuba Kunimasa was not without great thought.<br />I chose him for many reasons not the least of which was his incredible talent.<br /> He is also an Iaido and Aikido instructor, a man truly dedicated to the spiritual martial arts. <br /> He is mukansa master and has been awarded many honors. But most of all, Matsuba shared the vision of my idea.<br /> He had, not only the talent, but also the desire to be a part of samurai history. He has become my friend and martial arts guide.I am proud to know him.”</i></span><br /><br /><span><b>A. TAUM DELL’ARMO, FOUNDER/DIRECTOR, CARMEL SAMURAI COMPANY</b></span>",
        },
        {
          number: 2,
          title: "Phoenix",
          name: "Phoenix, Sword of Spiritual Renewal",
          subTitle: "",
          isSold: true,
          hasGallery:false,
          cover: require("@/assets/8samurai/2-cover.jpeg"),
          text: [
            { id: 1, content: "Private collection,USA" },
            { id: 2, content: "Custom forged by Yoshimoto Uchida" },
            {
              id: 3,
              content:
                "NBTHK Antique fittings/Phoenix, blossoms, paulownia tree",
            },
            { id: 4, content: "Part of the 8 Samurai Collector Series" },
            {
              id: 5,
              content:
                "In every culture, the Phoenix is a symbol of change, re-birth.<br> This client related to the theme and their sword features fittings of incredible beauty from private collections around the globe.<br>",
            },
          ],
          gallery: [
            {
              id: 1,
              src: require("@/assets/8samurai/2-1.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/2-1_thumbnail.jpeg"),
              w:1215,
              h:859,
              ratio: "1.7",
            },
            {
              id: 2,
              src: require("@/assets/8samurai/2-2.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/2-2_thumbnail.jpeg"),
              w:1488,
              h:859,
              ratio: "1.7",
            },
            {
              id: 3,
              src: require("@/assets/8samurai/2-3.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/2-3_thumbnail.jpeg"),
              w:1567,
              h:859,
              ratio: "1.7",
            },
            {
              id: 4,
              src: require("@/assets/8samurai/2-4.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/2-4_thumbnail.jpeg"),
              w:250,
              h:333,
              ratio: "1",
            },
            {
              id: 5,
              src: require("@/assets/8samurai/2-cover.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/2-cover_thumbnail.jpeg"),
              w:1968,
              h:859,
              ratio: "1.7",
            },
          ],
          extraContent:
            "<i>The choice of Yoshimoto Uchida is a perfect selection as he is an example of the newer, innovative generation of sword smiths.<br> While artistically guided by tradition and classically-trained, he is firmly devoted to transformative techniques and innovation to inspire beautiful creations in steel.</i>",
        },
        {
          number: 3,
          title: "Monk & The Dragon",
          name: "Monk & The Dragon",
          isSold: false,
          subTitle: "Pending",
          hasGallery:false,
          cover: require("@/assets/8samurai/3-cover.jpeg"),
          text: [
            { id: 1, content: "Pending/Accepting applications" },
            { id: 2, content: "Forged by: Sadaharu Minemoto" },
            {
              id: 3,
              content: "NBTHK Antique fittings/Dragon and Shishi (Lion Dog)",
            },
            {
              id: 4,
              content:
                "<br>This is the very first sword we did on “spec.”<br>We simply could not pass up the chance to have a rare Sadaharu blade,the renowned 20th century master of the classic Gassan school.<br><a class='white--text' href='/#/zen-teachings'>The sword is based on the Zen Teaching Story (we include the full legend here on our website; click to read).</a><br><br>The story, long a personal favorite, finds its incarnation in a sword of amazing beauty and artistic genius. Every single part of the creation carries a symbolic meaning pertaining to the tale.<br>Tsuba is Monk with the pot-of-gold that represents the “lure” of the material world. The dragon in the river, blocks the zen path. The Fuchi/Kashira is dragon-in-waves. Menuki are dragons in battle with Shishi (Lion-Dog). They are the Yin Yang guardians of temples and holy places. <br> The kenzen kado is the outcome of that battle; shishi, in this case the Aum dog (the one with the mouth open representing the oneness of the universe) has “restrained” the dragon (the obstacle).<br>The hamon is rolling wave. The ito is the green of the dense forest. Forests, symbolically, obscure the horizon/goal, block light, and hinder progress.<br>Finally the saya is “split” between the high-gloss, polished, lacquered surface, and the rough skin of the manta-ray; a symbol of nature, poverty, and harsh reality.<br>The mon (crest) of the Samurai Class is the cherry blossom that reminds the warrior that life is both beautiful and fleeting; quick to bloom and soon to die. This is the sword Monk & The dragon; a physical manifestation of a parable of spiritual quest. <br>Is this your sword?<br>Are you that samurai?",
            },
          ],
          gallery: [
            {
              id: 1,
              src: require("@/assets/8samurai/3-1.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/3-1_thumbnail.jpeg"),
              w:1631,
              h:859,
              ratio: "1.7",
            },
            {
              id: 2,
              src: require("@/assets/8samurai/3-2.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/3-2_thumbnail.jpeg"),
              w:1710,
              h:859,
              ratio: "1.7",
            },
            {
              id: 3,
              src: require("@/assets/8samurai/3-3.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/3-3_thumbnail.jpeg"),
              w:1528,
              h:859,
              ratio: "1.7",
            },
            {
              id: 4,
              src: require("@/assets/8samurai/3-4.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/3-4_thumbnail.jpeg"),
              h:859,
              w:888,
              ratio: "1.7",
            },
            {
              id: 5,
              src: require("@/assets/8samurai/3-5.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/3-5_thumbnail.jpeg"),
              h:859,
              w:1145,
              ratio: "1.7",
            },
            {
              id: 6,
              src: require("@/assets/8samurai/3-6.jpeg"),
              thumbnail: require("@/assets/8samurai/thumbnails/3-6_thumbnail.jpeg"),
              h:859,
              w:614,
              ratio: "1.7",
            },
          ],
          extraContent:
            "The beauty of this blade is the classic design of Sadaharu Minemoto.<br>A member of the Gassan Family School, the most famous, most respected school in Samurai history.A tradition of over 800 years.<br>Sadaharu Minemoto studied under the mentorship of not one, but two, Living Cultural Treasure legends.<br><br>The blade is thick and strong and the koshirae...... truly one-in-a-million.<br>Split saya of manta rayskin and high-gloss lacquer. Only other example of this quality of design and execution I have ever seen like this, is the famous 16th century saya by the legend, Hasebe Kunishige, designated Important Cultural Property, Japan's highest honor. <br><br>We used that design as our inspiration. We knew it would be a major undertaking and a difficult assignment.<br>It could only have been done by Japan's most respected koshirae team. We are grateful for their dedication and great skill.<br>This is a truly amazing koshirae with classic, signed fittings.",
        },
        {
          number: 4,
          title: "Crane",
          name: "Tsuru/Crane, Sword of Life",
          isSold: false,
          subTitle: "",
          hasGallery: true,
          cover: require("@/assets/8samurai/update1/P1.jpeg"),
          text: [
            { id: 1, content: "Not for Sale at this time/Inquiries Accepted" },
            {
              id: 2,
              content: "To be exhibited Summer '23, various art museums in US. Contact us for details and dates",
            },
            {
              id: 3,
              content:
                "Forged by: Gassan Sadatoshi, Japan's Legendary Swordsmith",
            },
            {
              id: 4,
              content: "NBTHK Antique fittings/Cranes, Turtle, Pine Tree",
            },
            { id: 5, content: "Horimono of Buddhist Bonji" },
            {
              id: 6,
              content:
                'Such is the nature of this sword tha it tells a story in steel and art.<br>Every feature is akin to a line of poetry or a verse in a sacred prayer.<br>The fittings are a Tosogu matched set from the famous Ishiguro School, c 17th century.<br>Menuki are solid gold.<br>The saya is hand-painted and lacquered by Japan\'s greatest living master.<br>The <a class="white-text font-weight-bold" style="color:white" href="/#/glossary/kenzen">kenzen kado</a> is a Buddhist symbol called, Navagrahas; 8 heavenly bodies surrounding the moon projecting great spiritual power.<br><br> 7 Masters, all legends, contributed to this one sword!<br><br>Everything about this sword is absolutely unmatched in detail and craftsmanship.This will be koshirae of legacy-quality befitting the masters involved.<br><br>Symbols are Crane, Turtle, and Pine. Japan\'s most classic and revered icons of myth and legend.<br>They represent themes of Spiritual Life, Longevity, Devotion, and Wisdom.<br><br>The Crane is master of all realms. He walks the earth, swims the seas, and soars the Heavens. Samurai\'s most powerful symbol is the theme of our most powerful sword.<br><br>The future owner of this sword will have the honor of an historically-important piece of samurai art,<br><br>Inquiries will be accepted through exhibition dates.',
            },
          ],
          gallery: [
            {
              id: 1,
              text: "Solid gold menuki & habaki<br>Notice the incredible detail of fuchi and tsuba rim",
              pic: require("@/assets/8samurai/update1/P2.jpeg"),
              hasSold: false,
            },
            {
              id: 2,
              text: "The superior quality of the Sadatoshi hamon w/hand carved bonji in relief",
              pic: require("@/assets/8samurai/update1/P4.jpeg"),
              hasSold: false,
            },
            {
              id: 3,
              text: "As fine an example of maki-e (the 3,000 year old art) as you will ever see.<br>To achieve such magnificent detail, it took Japan's greatest artisan almost 2 years  to complete.",
              pic: require("@/assets/8samurai/update1/P5.jpeg"),
              hasSold: false,
            },
            {
              id: 4,
              text: "Incredible beauty at any angle",
              pic: require("@/assets/8samurai/update1/P6.jpeg"),
              hasSold: false,
            },
            {
              id: 141,
              text: "",
              pic: require("@/assets/8samurai/update4/1.jpg"),
              hasSold: false,
            },
            {
              id: 151,
              text: "",
              pic: require("@/assets/8samurai/update4/2.jpg"),
              hasSold: false,
            },
            {
              id: 162,
              text: "",
              pic: require("@/assets/8samurai/update4/3.jpg"),
              hasSold: false,
            },
            {
              id: 161,
              text: "",
              pic: require("@/assets/8samurai/update4/4.jpeg"),
              hasSold: false,
            },
            {
              id: 5,
              text: "The kenzen-kado (see our Glossary section) is the sacred, though simple, Buddhist symbol, The Navagraha.Each circle represents a heavenly body that controls our Fate. The crane sits at point West. In this position it represents the planet Saturn, ruled by the god Sani, the Lord of Justice who controls the karmic-effects of a well-lived life.",
              pic: require("@/assets/8samurai/update1/P7.jpeg"),
              hasSold: false,
            },
            {
              id: 6,
              text: "",
              pic: require("@/assets/8samurai/update1/F1.jpeg"),
              hasSold: false,
            },

            {
              id: 7,
              text: "It is the most beautiful sword ever made, by the greatest master of the most famous school who ever forged samurai swords",
              pic: require("@/assets/8samurai/update1/B1.jpeg"),
              hasSold: false,
            },
            {
              id: 8,
              text: "It is the most beautiful sword ever made, by the greatest master of the most famous school who ever forged samurai swords",
              pic: require("@/assets/8samurai/update1/B2.jpeg"),
              hasSold: false,
            },
            {
              id: 9,
              text: "It is the most beautiful sword ever made, by the greatest master of the most famous school who ever forged samurai swords",
              pic: require("@/assets/8samurai/update1/B3.jpeg"),
              hasSold: false,
            },
            {
              id: 10,
              text: "It is the most beautiful sword ever made, by the greatest master of the most famous school who ever forged samurai swords",
              pic: require("@/assets/8samurai/update1/B4.jpeg"),
              hasSold: false,
            },

            {
              id: 11,
              text: "",
              pic: require("@/assets/8samurai/update1/S1.jpeg"),
              hasSold: false,
            },
            {
              id: 12,
              text: "",
              pic: require("@/assets/8samurai/update1/S2.jpeg"),
              hasSold: false,
            },
            {
              id: 13,
              text: "",
              pic: require("@/assets/8samurai/update1/F2.jpeg"),
              hasSold: false,
            },

          ],
          extraContent:
            "The Sword of the Master!<br>The greatest sword of the greatest living swordsmith master, Gassan Sadatoshi.<br><br>The Gassan School is justifiably Japan's most famous and respected.<br>Sadatoshi, current HeadMaster has received every honor his craft offers.<br>He is the living continuation of a family of smiths that has forged fine swords for 844 years!<br><br> This magnificent blade is wide, powerful and utterly impressive. The master has hand-carved his own signature horimono of a sacred Buddhist bonji, a symbol of spiritual power.<br><br>Mukansa Master Sadatoshi has been the recipient of every honor in his field, including the Order of the Rising Sun, presented by the Emperor of Japan.<br>He is the son of the the late Gassan Sadaichi, former Living Cultural Treasure, and is rumored to be next-in-line for that honor himself.<br><br><a class='ac white--text' href='/#/gallery/visit/'>My visit with the Legend and his son</a> <br><br>" + '<iframe width="560" height="315" src="https://www.youtube.com/embed/VE_4zHNcieM?controls=0&amp;start=768" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>'

        },
      ],
    };
  },
  computed: {
    related() {
      let list = [];
      for (let i = 1; i < this.swords.length; i++) {
        if (this.activeSword.number != this.swords[i].number) {
          list.push({
            number: this.swords[i].number,
            name: this.swords[i].title,
            cover: this.swords[i].cover,
            url:
              "/the-8-samurai/" +
              this.$urlHelper.fixDiacritice(this.swords[i].name) +
              "-" +
              this.swords[i].number,
          });
        }
      }
      return list;
    },
    activeSword() {
      if (typeof this.swords[this.$route.params.id] === "undefined") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$router.push({ path: "/404" });
      }
      if (this.$route.params.id === "0") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$router.push({ path: "/404" });
      }
      return this.swords[this.$route.params.id];
    },
  },
};
</script>
<style>
.my-gallery > figure {
  display: block;
  border: 1px solid grey;
  float: left;
}
</style>
