<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">Contact Us</h1>
      </v-card-title>
    </v-banner>
    <v-banner style="background-color: rgba(0, 0, 0, 0.5)">
      <h1 class="ac mx-4 mt-4 justify-center text-center">
        "There is nothing between Heaven & Earth a man need fear<br><br>
        Who carries at his side this magnificent blade"
      </h1><br><br>
      <h3 class="ac text-right mx-4">
        -Matsume, 13th Centrury<br>
        Japan's Greatest Swordsmith
      </h3>
    </v-banner>
    <v-row class="mt-8">
      <v-col cols="12">
        <form ref="form">
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            name="name"
            :counter="100"
            label="Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            name="email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="subject"
            name="subject"
            :error-messages="subjectErrors"
            label="Subject"
            required
            @input="$v.subject.$touch()"
            @blur="$v.subject.$touch()"
          ></v-text-field>
          <v-textarea
            v-model="message"
            name="message"
            :error-messages="messageErros"
            label="Your Message"
            required
            @change="$v.message.$touch()"
            @blur="$v.message.$touch()"
          ></v-textarea>

          <v-btn class="mr-4" @click="submit"> submit </v-btn>
          <v-btn @click="clear"> clear </v-btn>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import emailjs from "@emailjs/browser";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(100) },
    email: { required, email },
    subject: { required },
    message: { required },
  },
  name: "ContactPage",

  data() {
    return {
      name: "",
      email: "",
      message: "",
      subject: "",
      imgLink: {
        background: require("@/assets/contact.jpg"),
      },
    };
  },
  computed: {
    messageErros() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Your message is required.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      !this.$v.subject.required && errors.push("Subject is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },

  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_3fxb48k",
          "template_xbdye6o",
          this.$refs.form,
          "user_51Ysf9rBUAdO0WYzmJuRx"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
    submit: function () {
      try {
        this.sendEmail();
      } catch (error) {
        console.log({ error });
      }
      this.$v.$touch();
      this.$toast("Your message has been sent");
      this.clear();
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.subject = "";
      this.email = "";
      this.message = "";
    },
  },
};
</script>
