<template>
  <v-container>
    <v-card shaped class="ma-5" elevation="10">
      <v-parallax :src="imageLink.notFound" height="600">
        <v-layout column align-center justify-center>
          <div
            class="mx-10 py-5 px-5"
            style="background-color: rgba(0, 0, 0, 0.5)"
          >
            <h1 class="" style="font-weight: 900; text-shadow: 3px 2px #000000">
              The page you are looking for is not here
            </h1>
            <div
              class="ac"
              style="font-weight: 900; text-shadow: 2px 2px #000000"
            >
              Please return to the previous page and try again
            </div>
            <v-btn class="ac lighten-2 mt-5" dark large @click="goBack">
              Go Back
            </v-btn>
          </div>
        </v-layout>
      </v-parallax>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  title: "404 Page Not Found",
  data: () => {
    return {
      imageLink: {
        notFound: require("@/assets/1-SamuraiTaum.jpg"),
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
