import Vue from "vue";
import VueRouter from "vue-router";
import CoverPage from "@/views/CoverPage.vue";
import Home from "@/views/Home";
import NotFound from "@/components/NotFound";
import EightSwords from "@/views/EightSwords";
import EightSwordPage from "@/views/swords/8samuraisword";
import SevenSisters from "@/views/SevenSisters";
import SevenSisterPage from "@/views/swords/7sisters";
import About from "@/views/About";
import ZenTeaching from "@/views/ZenTeaching";
import Links from "@/views/Links";
import Contact from "@/views/Contact";
import Glossary from "@/views/Glossary";
import Swordsandpeople from "@/views/Swordsandpeople";
import Galleryvisit from "@/views/galleryvisit";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "CoverPage",
    component: CoverPage,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: true },
  },
  {
    path: "/glossary",
    name: "Glossary",
    component: Glossary,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/gallery/visit",
    name: "Gallery - My Visit",
    component: Galleryvisit,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/swords-and-people",
    name: "Swords",
    component: Swordsandpeople,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/glossary/:term",
    name: "GlossaryFilter",
    component: Glossary,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/links",
    name: "Links",
    component: Links,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/zen-teachings",
    name: "ZenTeaching",
    component: ZenTeaching,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/zen-teachings/:term",
    name: "ZenFilter",
    component: ZenTeaching,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/the-8-samurai/:slug-:id",
    component: EightSwordPage,
    name: "EightSwordPage",
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/the-pleiades/:slug-:id",
    component: SevenSisterPage,
    name: "SevenSisterPage",
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/the-8-samurai-series",
    name: "The8SamuraiSeries",
    component: EightSwords,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "/the-pleiades",
    name: "The7Sisters",
    component: SevenSisters,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: false },
  },
  {
    path: "*",
    component: NotFound,
    meta: { requiresLogin: false, requiresInit: true, hideNavbar: true },
  },
];

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes,
});
export default router;
