import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ripple from "vuetify/lib/directives/ripple";

Vue.use(Vuetify, { directives: { ripple } });

const vuetify = new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: "mdi-menu-down" },
  },
  rtl: false,
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#262626",
        secondary: "#444",
        accent: "#8B94A3",
        error: "#FF715B",
        info: "#e2f5f5",
        success: "#4cae1f",
        warning: "#DB5461",
      },
    },
  },
});
export default vuetify;
