<template>
  <v-container>
    <v-banner>
      <v-card-title class="justify-center mb-4">
        <h1 class="text-center ac">The 8 Samurai</h1>
      </v-card-title>
      <v-card-subtitle>
        <h2 class="text-center ac">
          The Most Exclusive Art Club in the World
        </h2>
      </v-card-subtitle>
      <v-img :src="imgLink.title" width="100%" height="100%"></v-img>
    </v-banner>
    <v-banner>
      <v-parallax id="hero" dark :src="imgLink.background" :height="$vuetify.breakpoint.mobile ? 900: 680" style="border: 1px solid #bfae2c">
        <v-row class="mt-4">
          <v-col cols="12">
            <v-card
              class="mx-auto ac justify-center"
              max-width="720"
              min-height="600"
              elevation="10"
              tile
              outlined
            >
              <v-card-title class="justify-center"
                ><h3 class="text-center ac">
                The First Limited Edition Series in Samurai Sword History
                </h3></v-card-title
              >
              <v-list dense>
                <v-list-item >
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>
                        8 Master swordsmiths forge 8 blades for 8 select
                        clients.
                      </h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>
                        Each sword mounted with genuine antique samurai
                        fittings.
                      </h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3 >
                        All registered with NBTHK (Society for the Preservation
                        of the Samurai Sword).
                      </h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>Each sword signed by both Master and owner.</h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon>
                  <v-list-item-content>
                      <h3>
                        Each owner wears the gold ring forged with a piece of
                        steel from the blade.
                      </h3>
                      <v-list-item-subtitle class="mt-1">
                        <h3>
                          Each ring bears the club’s gold logo 8 with samurai
                          “S”
                        </h3></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>
                        Every owner gets a 75-page book with photos, text, background, provenance of fittings and complete story of the creation of their sword.
                      </h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>
                        At the completion of the Series there will be a final
                        book telling the story of the 8 Samurai.
                      </h3>
                    <v-list-item-subtitle class="mt-1">
                      <h3>
                        All 8 original members will be in the book with their
                        swords and a copy of the book will be sent to every
                        major art museum in the world that has a samurai
                        collection.
                      </h3>
                    </v-list-item-subtitle>
                      <h3>
                        What other piece of art do you own that will give you
                        immortality?
                      </h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>
                        Only 8 swords will ever be created.<br />
                        Only 8 members.Today, tomorrow, 100 years from now.
                      </h3>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon class="mr-2"
                    ><v-icon>done</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                      <h3>
                        In the 1,000 year history of the samurai sword, nothing
                        like this has ever been done before.
                      </h3>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-parallax>
    </v-banner>
    <v-row class="mt-2">
      <v-col cols="12">
        <div class="mx-4 text-break">
          How it works:<br /><br />

          Initial contact usually occurs when an art broker or agent contacts us
          on behalf of their client.<br />
          After preliminary discussions, if a client is chosen, anywhere in the
          world, we send a First-Class airline ticket with an invitation to
          visit us here in Carmel.<br /><br />

          Accommodations are made for a suite at the world-renowned golf retreat
          where my family<br />
          and I live. All “business” is conducted in our home. As we said,
          custom and personal.<br />
          We choose a “theme” - something unique and meaningful to that person -
          and then select a master to forge their sword.<br /><br />

          We then begin a worldwide search involving private collectors and
          museums for antique fittings that match that theme.<br /><br />

          Such personal care and commitment takes a great deal of time.<br />
          It can take more than 2 years to complete one project!<br /><br />

          Blade and fittings are not the end, they are barely the beginning.<br />
          We choose a master artisan team to create the saya (scabbard), the
          handle, the silk wrap, the saya finish, etc.<br /><br />

          Amazingly, it takes 7 Masters and more than two dozen workers for each
          project.<br />
          To become a Master, one must undergo an apprenticeship under a
          licensed Master for several years, then pass a rigorous test of
          excellence.<br />
          We use only recognized masters.<br /><br />

          No other work of art in all of history compares to the collaboration
          of genius and artistic talent that comprise the samurai sword.<br /><br />

          All our swords, like the antique fittings that grace the finished
          piece, are registered with the NBTHK (Society for the Preservation of
          the Samurai Sword).<br /><br />

          We create, for each owner, an art treasure unique in all the world.<br /><br />

          Only 8 swords will be created.<br />
          Only 8 people will own a piece of history.<br /><br />

          It is, the most exclusive art club in the world.<br />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="sword in swords"
        :key="sword.id"
        cols="12"
        md="3"
        lg="3"
        sm="12"
      >
        <v-card class="mx-auto" :to="sword.url">
          <v-img :src="sword.cover" height="130" contain></v-img>
          <v-card-title class="text-break"> #{{ sword.number }} {{ sword.name }} </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "HomePage",
  components: {},
  metaInfo() {
    return {
      title: "The 8 Samurai Series",
      meta: [
        {
          name: "description",
          content:
            "8 Master swordsmiths forge 8 blades for 8 select clients.\n",
        },
        {
          property: "og:title",
          content:
            "8 Master swordsmiths forge 8 blades for 8 select clients.\n",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      imgLink: {
        background: require("@/assets/86-Blade_Hamon.jpeg"),
        title: require("@/assets/8samurai/update2/header.jpeg"),
      },
      swords: [
        {
          number: 1,
          name: "Samurai Spring - SOLD",
          isSold: true,
          cover: require("@/assets/8samurai/1-cover.jpeg"),
          url:
            "/the-8-samurai/" +
            this.$urlHelper.fixDiacritice("Samurai Spring") +
            "-1",
        },
        {
          number: 2,
          name: "Phoenix - SOLD",
          isSold: true,
          cover: require("@/assets/8samurai/2-cover.jpeg"),
          url:
            "/the-8-samurai/" +
            this.$urlHelper.fixDiacritice(
              "Phoenix, Sword of Spiritual Renewal"
            ) +
            "-2",
        },
        {
          number: 3,
          name: "Monk & The Dragon",
          isSold: true,
          cover: require("@/assets/8samurai/3-cover.jpeg"),
          url:
            "/the-8-samurai/" +
            this.$urlHelper.fixDiacritice("Monk & The Dragon") +
            "-3",
        },
        {
          number: 4,
          name: "Crane",
          isSold: true,
          cover: require("@/assets/8samurai/update1/P1.jpeg"),
          url:
            "/the-8-samurai/" +
            this.$urlHelper.fixDiacritice("Crane / Sword of Life") +
            "-4",
        },
      ],
    };
  },
};
</script>
<style>
#hero .v-parallax__content {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.95) 25%,
    rgba(0, 0, 0, 0.55) 50%,
    rgba(0, 0, 0, 0.95) 75%,
    rgba(0, 0, 0, 1) 100%
  );
}
h3.ac {
  font-size: 17px;
}
h3 {
  font-size: 16px;
  line-height: 1.1;
}
</style>
