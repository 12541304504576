<template>
  <v-container>
    <v-row class="mt-8 d-flex flex">
      <v-col cols="12">
        <v-card tile outlined>
          <v-card-title>
            <h1 class="ac">Glossary</h1>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search...."
              class="ac"
              color="white"
              style="font-size: 17px"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            hide-default-header
            hide-default-footer
            elevation="5"
            :headers="headers"
            :items="items"
            :search="search"
            items-per-page="1000"
            sort-by="title"
            calculate-widths="true"
          >
            <template v-slot:item.title="{ item }">
              <h1 style="width: 220px" class="ac my-2 py-2">
                {{ item.title }}
              </h1>
            </template>
            <template v-slot:item.desc="{ item }">
              <h3 class="ac my-2 py-2">{{ item.desc }}</h3>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "HomePage",
  data() {
    return {
      search: "",
      imgLink: {
        background: require("@/assets/homePage.jpeg"),
      },
      headers: [
        { text: "Term", value: "title" },
        { text: "Description", value: "desc" },
      ],
      items: [
        {
          title: "Fuchi/Kashira",
          desc: "The artistic fittings that sit at the front and end of the tsuka (handle). These two pieces comprise a set and are always the work of a single artist",
        },

        {
          title: "Habaki",
          desc: "Wedge shaped collar used to keep the sword from falling out of the saya and to support the tsuka. Custom made for each blade by a Master it can be gold, silver, or other metal wrapped around a copper core.",
        },

        {
          title: "Horimono",
          desc: "generic term for all carvings on a blade including grooves. More specifically, they usually refer to figures of legend, myth, or spiritual meaning.\n",
        },

        {
          title: "Menuki",
          desc: "These are small ornaments on the hilt of a sword<br>Traditionally thought to be for improving the grip when wielding thesword it is also chosen for its beauty.",
        },
        {
          title: "Tsuba-Sword guard.",
          desc: "Prevents the hands of the samurai from sliding forward unto the sharp blade. Also, a protection from an opponent’s attack<br>Also serves to balance the sword when holding.",
        },
        {
          title: "Kojiri",
          desc: "Fitting made of horn (water buffalo) or metal protecting the butt-end of the saya.",
        },
        {
          title: "Saya",
          desc: "Scabbard. Custom made for each blade",
        },
        {
          title: "Samegawa (Same)",
          desc: "Skin from the belly of a ray (cowtail stingray). Has a rough, granular surface. Can be used for saya decoration but is mainly to cover the tsuka.",
        },
        {
          title: "Kogai",
          desc: "Literally “sword needle.” Used as utility tool by samurai but also conveyed the wealth and status of the owner. Highly artistic collectible.",
        },
        {
          title: "Kozuka",
          desc: "Small all-purpose knife mounted on the inside of the saya. The term refers to the handle/hilt and is a highly collected piece of art. Blade is kogatana.",
        },
        {
          title: "Kaerizuno/Saguri",
          desc: "A hook on the saya to secure the sword on the belt (obi) to keep it from slipping",
        },
        {
          title: "Kenzen-Kado",
          desc: "Literally, “the Gate between Zen and the art of wielding the sword.” This is a unique feature of ataum swords. All my swords feature this  and the fitting is always related to the overall theme of the sword-project. No other samurai swords in the world have this. The drawing of the sword begins when the samurai places the palm of his left hand over this spot. It is the first and last contact with the sword and thus placing a fitting here represents a physical feature of a symbolic spiritual act where the samurai fulfills the ideal of becoming one with his sword.",
        },
        {
          title: "Kurigata",
          desc: "Fitting (made of water-buffalo horn or soft metal) for the sageo.",
        },
        {
          title: "Sageo",
          desc: "Cord fitted through the kurigata used to tie the saya to the obi when worn.",
        },
        {
          title: "Tsuka",
          desc: "Handle of a sword",
        },
        {
          title: "Kissaki",
          desc: "The fan-shaped area at the tip of the sword. An extremely important artistic feature, it gets special attention from the swordsmith during the forging process and from the polisher. Small, delicate, and rare polishing stones ranging up to thousands of dollars an ounce are used exclusively for the kissaki.",
        },
        {
          title: "Hamon",
          desc: "Literally “blade patterns” it is the signature feature of the swordsmith’s art. Technically, the hamon outlines the transition between the region of the harder martensite steel at the blade’s edge and the softer pearlite steel at center and back of the sword.  Functional, yet it is the aesthetic qualities of the hamon that set its artistic value. The patterns actually exist at different levels in the steel and can only be viewed fully by examination at various angles with different light. Extremely complex, hundreds of words and terms are needed to fully explain, define, and rate the hamon of a samurai sword.",
        },
        {
          title: "Nakago",
          desc: "Tang of a sword.Never polished, it is the area where all the information is engraved including name of swordsmith, date, prefecture (area), school, etc",
        },
        {
          title: "Wakizashi",
          desc: "Short sword with a blade length between 12-24 inches. The samurai’s companion of the katana in a daisho pair. Only a samurai could carry a katana but the wakizashi was a favorite of noblemen, mechants, members of the court and always carried as a symbol of wealth and position.",
        },
        {
          title: "Tanto - knife or dagger",
          desc: "Blade that measures less than 12”",
        },
        {
          title: "Katana",
          desc: "The long sword of the samurai. A blade measured over 24.” By decree, only a samurai could carry a katana. Strictly enforced.  Since samurai was a protected “class,” offenders faced the death penalty by this breaking tradition.",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.term !== "undefined") {
      this.search = this.$route.params.term;
    }
  },
};
</script>
<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
