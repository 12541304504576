<template>
  <v-row data-app="true">
    <v-col>
      <v-card :elevation="typeof elevation === 'undefined' ? 0 : elevation">
        <v-container fluid>
          <v-row cols="12" sm="9" offset-sm="1">
            <v-col
              v-for="(image, index) in images"
              :key="index"
              class="d-flex child-flex"
            >
              <v-card flat tile class="d-flex">
                <v-img
                  :src="image.imgSrc"
                  :aspect-ratio="image.ratio"
                  :max-height="typeof height === 'undefined' ? 520 : height"
                  class="grey lighten-2"
                  :contain="typeof contain !== 'undefined' ? contain : false"
                  @click.stop="
                    (dialog = true), (id = index), (imgSrc = image.imgSrc)
                  "
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
            <v-dialog v-model="dialog" is-dark="">
              <v-card>
                <v-img :src="imgSrc" contain height="720px"></v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="red darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["images", "contain", "elevation", "height"],
  data() {
    return {
      dialog: false,
      imgSrc: "",
    };
  },
};
</script>
